export const useAPI = () => {
  function insertParamsToQuery(params) {
    let res = ""
    Object.entries(params).forEach(el => {
      res += `${el[0]}=${el[1]}&`;
    })
    return res.slice(0, -1)
  }

  async function getMap({locations, data_type, years}) {
    if(years.length == 1) years = [years[0], years[0]]
    let params = {
      "locations": locations,
      "data_type": data_type,
      "time_interval": 'yearly',
      "start_dt": `${years[0]}-01-01`,
      "end_dt": `${years[1]}-12-31`,
      "language": "ukrainian"
    }
    //let url = `http://api.uhmi.org.ua/v2/agriculture_observation?${insertParamsToQuery(params)}`;
    //let url = `http://212.26.138.5:6660/agriculture_observation?${insertParamsToQuery(params)}`;
    let url = `https://test.api.uhmi.org.ua/agriculture_observation/?${insertParamsToQuery(params)}&api_key=${document.cookie.split('; ').find(row => row.startsWith('APIToken'))?.split('=')[1]}`;

    let resp = await fetch(url)
    let body = await resp.json();

    if(body.error) {
      alert(body.error);
      console.error(body.error);
    }

    //console.log(body);
    return body;
  }

  async function getLocations() {
    let params = {
      "api": "agriculture",
      "key": "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYyOTQwMjg5NywiaWF0IjoxNjI5NDAyODk3fQ.NRZxQT8LQws7vq5ZQAUZI4OZb37BAvvuvFb1Du8Nr2w"
    }
    let url = `https://test.api.uhmi.org.ua/locations?${insertParamsToQuery(params)}&api_key=${document.cookie.split('; ').find(row => row.startsWith('APIToken'))?.split('=')[1]}`;

    let resp = await fetch(url)
    let body = await resp.json();

    if(body.error) {
      alert(body.error);
      console.error(body.error);
    }

    //console.log(body);
    return body;
  }

  return { getMap, getLocations }
}