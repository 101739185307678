<template>
  <div
    class="popup__wrapper"
    id="AboutPopup"
    @click="useTogglePopup('AboutPopup')"
  >
    <div
      class="popup"
      @click.stop
    >
      <div
        class="popup__cross"
        @click="useTogglePopup('AboutPopup')"
      >
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="26"
            cy="26"
            r="25"
            fill="#FFF7BC"
            stroke="#EC7014"
            stroke-width="2"
          />
          <path
            d="M16.7368 15.293L36.6186 35.1748"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M15.2928 35.2339L35.1747 15.3521"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="popup__content">
        <article
          class="article"
          v-for="article in content"
        >
          <h1
            class="article__heading"
            v-html="article.heading?.[chosenLang.id]"
            :id="`article-${article.id}`"
          ></h1>

          <div
            class="article__content"
            v-html="article.content?.[chosenLang.id]"
          ></div>
        </article>
      </div>
      <div class="popup__menu">
        <a
          class="menu__heading"
          v-for="article in content"
          v-html="article.heading?.[chosenLang.id]"
          :class="{ 'menu__heading--active': activeHeadingId == article.id }"
          :href="`#article-${article.id}`"
        ></a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTogglePopup } from "@/composables/popup";
import { useLangStore } from "@/stores/useLangStore";
import { computed, onMounted, ref } from "vue";

const content = ref([
  {
    id: 0,
    heading: {
      "ua": "Що таке AgroStats?",
      "en": "What is AgroStats?"
    },
    content: {
      "ua": `Онлайн платформа AgroStats містить відкриті дані державної статистики сільського господарства України. Охоплено період 1980-2023 рр. для України та 1990-2023 рр. для областей України.
      <br/><br/>
      Аграріям AgroStats може допомогти оцінити потенційний виробничий результат, вибрати найбільш прибуткові культури, визначити необхідну кількість насіння та добрив.
      <br/><br/>
      Науковці та держслужбовці агросектору за допомогою AgroStats можуть оцінити динаміку розвитку рослинництва України, зміну відсотка посівних площ, тренди врожайності та внесення добрив.
      <a class="article__download" href='/Буклет_Рішення боротьби з посухою.pdf' target='_blank'>Буклет_Рішення боротьби з посухою.pdf<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.108704 0V24H18.8913V6.57065L18.7446 6.40761L12.4837 0.146739L12.3207 0H0.108704ZM1.15218 1.04348H11.587V7.30435H17.8478V22.9565H1.15218V1.04348ZM12.6304 1.79348L17.0978 6.26087H12.6304V1.79348ZM4.28262 10.4348V11.4783H14.7174V10.4348H4.28262ZM4.28262 13.5652V14.6087H12.6304V13.5652H4.28262ZM4.28262 16.6957V17.7391H14.7174V16.6957H4.28262Z" fill="black"/></svg></a>
      `,
      "en": `The online platform AgroStats contains open data from Ukraine's state statistics. It covers the period 1980-2023 for Ukraine and 1990-2023 for the regions of Ukraine.
      <br/><br/>
      For agrarians, AgroStats can help assess potential production outcomes, choose the most profitable crops, and determine the necessary amount of seeds and fertilizers.
      <br/><br/>
      Scientists and officials in the agricultural sector can use AgroStats to evaluate the dynamics of Ukraine's plant cultivation, changes in the percentage of sown areas, yield trends, and fertilizer application.
      <a class="article__download" href='/Буклет_Рішення боротьби з посухою.pdf' target='_blankw'>Booklet_Drought mitigation (In Ukrainian).pdf<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.108704 0V24H18.8913V6.57065L18.7446 6.40761L12.4837 0.146739L12.3207 0H0.108704ZM1.15218 1.04348H11.587V7.30435H17.8478V22.9565H1.15218V1.04348ZM12.6304 1.79348L17.0978 6.26087H12.6304V1.79348ZM4.28262 10.4348V11.4783H14.7174V10.4348H4.28262ZM4.28262 13.5652V14.6087H12.6304V13.5652H4.28262ZM4.28262 16.6957V17.7391H14.7174V16.6957H4.28262Z" fill="black"/></svg></a>
      `
    }
  },
  {
    id: 1,
    heading: {
      "ua": "Сільськогосподарські статистичні дані, які представлені на платформі",
      "en": "Agricultural statistical data presented on the platform"
    },
    content: {
      "ua": `<strong>Статистичні параметри:</strong>
      <ul>
        <li>Урожайність с/г культур</li>
        <li>Посівна площа</li>
        <li>Внесення мінеральних та органічних добрив</li>
        <li>Зрошення с/г культур</li>
      </ul>
        <strong>Сільськогосподарські культури:</strong>
      <ul>
        <li>Загальні категорії:
          <ul>
            <li>Зернові</li>
            <li>Технічні</li>
            <li>Овочі</li>
            <li>Кормові</li>
            <li>Фрукти</li>
          </ul>
        </li>
        <li>Окремі культури:
          <ul>
            <li>Озима та ярова пшениця</li>
            <li>Ячмінь</li>
            <li>Кукурудза на зерно</li>
            <li>Жито</li>
            <li>Овес</li>
            <li>Соняшник</li>
            <li>Буряк</li>
            <li>Соя</li>
            <li>Ріпак</li>
            <li>Картопля</li>
          </ul>
        </li>
      </ul>`,

      "en": `<strong>Statistical parameters:</strong>
      <ul>
        <li>Crop yield</li>
        <li>Sown area</li>
        <li>Application of mineral and organic fertilizers</li>
        <li>Irrigation of crops</li>
      </ul>
        <strong>Crops:</strong>
      <ul>
        <li>General categories:
          <ul>
            <li>Grain crops</li>
            <li>Industrial crops</li>
            <li>Vegetables</li>
            <li>Fodder crops</li>
            <li>Fruits</li>
          </ul>
        </li>
        <li>Specific crops:
          <ul>
            <li>Winter and spring wheat</li>
            <li>Barley</li>
            <li>Corn for grain</li>
            <li>Rye</li>
            <li>Oats</li>
            <li>Sunflower</li>
            <li>Sugar beet</li>
            <li>Soybean</li>
            <li>Rape</li>
            <li>Potato</li>
          </ul>
        </li>
      </ul>`
    }
  },
  {
    id: 2,
    heading: {
      "ua": "Посилання",
      "en": "Citation"
    },
    content: {
      "ua": `Під час використання даних посилатися на <a href="https://ukrstat.gov.ua/" target="_blank">Державну службу статистики України</a> (див. Джерела інформації)
      <br/><br/>
      Під час використання малюнків посилатися на <a href="agrostats.uhmi.org.ua">agrostats.uhmi.org.ua</u>`,

      "en": `When using the data, refer to <a href="https://ukrstat.gov.ua/" target="_blank">the State Statistics Service of Ukraine</a> (see Sources of Information).
      <br/><br/>
      When using the images, refer to <a href="agrostats.uhmi.org.ua">agrostats.uhmi.org.ua</u>`
    }
  },
  {
    id: 3,
    heading: {
      "ua": "Джерела інформації",
      "en": "Sources of information"
    },
    content: {
      "ua": `Інформацію зібрано за матеріалами <a href="https://ukrstat.gov.ua/" target="_blank">Державної служби статистики України</a>
    <br/><br/>
    <strong>Урожайність</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_rosl_zb.htm" target="_blank">Збірник</a> “Рослинництво України”, 2009-2021 роки
        <ul><li>Таблиці у збірнику “Урожайність … <em>(назва сільськогосподарської культури)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/operativ2022/sg/pvzu/arch_pvxu_reg.htm" target="_blank">Бюлетень</a> “Площі, валові збори та урожайність сільськогосподарських культур за їх видами та по регіонах”, 2022 рік
        <ul><li>Таблиці у бюлетені “Виробництво … <em>(назва сільськогосподарської культури)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/menu/menu_u/cg.htm" target="_blank">Таблиця</a> “Рослинництво”, 1991-2022 роки</li>
      <li><a href="http://nbuv.gov.ua/node/3857" target="_blank">Щорічник</a> “Народне господарство Української РСР», 1990 рік
        <ul>
          <li>Таблиця у щорічнику ”Урожайність сільськогосподарських культур”</li>
          <li>Таблиця у щорічнику “Урожайність окремих зернових культур (у вазі після доробки)”</li>
          <li>Таблиця у щорічнику “Валовий збір та урожайність плодів, ягід і винограду”</li>
        </ul>  
      </li>
    </ol>
    
    <strong>Посівні площі</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_rosl_zb.htm" target="_blank">Збірник</a> “Рослинництво України”, 2009-2021 роки
        <ul><li>Таблиці у збірнику “Площа, з якої зібрано … <em>(назва сільськогосподарської культури)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/operativ2022/sg/pvzu/arch_pvxu_reg.htm" target="_blank">Бюлетень</a> “Площі, валові збори та урожайність сільськогосподарських культур за їх видами та по регіонах”, 2022 рік
        <ul><li>Таблиці у бюлетені  “Виробництво… <em>(назва сільськогосподарської культури)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/01/Arch_zor_zb.htm" target="_blank">Щорічник</a> “Статистичний щорічник України”, 2007-2021 роки
        <ul>
          <li>Таблиця у щорічнику “Посівні площі культур сільськогосподарських”</li>
          <li>Таблиця у щорічнику “Посівні площі культур сільськогосподарських за регіонами”</li>
        </ul>
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/menu/menu_u/cg.htm" target="_blank">Таблиця</a> “Рослинництво”, 1991-2022 роки</li>
      <li><a href="http://nbuv.gov.ua/node/3857" target="_blank">Щорічник</a> “Народне господарство Української РСР», 1990 рік
        <ul>
          <li>Таблиця у щорічнику “Посівні площі”</li>
          <li>Таблиця у щорічнику “Площа плодово-ягідних і виноградних насаджень”</li>
        </ul>
      </li>
    </ol>
    
    <strong>Добрива</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_mod_bl.htm" target="_blank">Бюлетень</a> “Внесення мінеральних та органічних добрив під урожай сільськогосподарських культур”, 2009-2017 роки
        <ul>
          <li>Таблиця у бюлетені “Внесення мінеральних і органічних добрив під урожай сільськогосподарськими підприємствами”</li>
          <li>Таблиці у бюлетені “Внесення мінеральних і органічних добрив під посіви … <em>(назва сільськогосподарської культури)</em> сільськогосподарськими підприємствами”</li>
        </ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/operativ2018/sg/vmod/arch_vmodsg_u.htm" target="_blank">Бюлетень</a> “Використання добрив і пестицидів під урожай сільськогосподарських культур”, 2018-2022 роки
        <ul>
          <li>Таблиця у бюлетені “Площа, на якій були застосовані добрива та пестициди під урожай сільськогосподарських культур по регіонах”</li>
          <li>Таблиця у бюлетені “Унесення мінеральних добрив (у діючій речовині) під урожай сільськогосподарських культур по регіонах”</li>
          <li>Таблиця у бюлетені “Унесення органічних добрив під урожай сільськогосподарських культур по регіонах”</li>
        </ul>    
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/menu/menu_u/cg.htm" target="_blank">Таблиця</a> “Внесення мінеральних та органічних добрив, застосування пестицидів”, 1990-2022 роки</li>
      <li><a href="http://nbuv.gov.ua/node/3857" target="_blank">Щорічник</a> “Народне господарство Української РСР», 1990 рік
        <ul><li>Таблиця у щорічнику “Поставка сільському господарству мінеральних добрив”</li></ul>
      </li>
    </ol>

    <strong>Полив</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_dov_zb.htm" target="_blank">Збірник</a> “Довкілля України”, 2009-2020 роки
        <ul>
          <li>Таблиця у збірнику “Основні показники використання та відведення води”</li>
          <li>Таблиця у збірнику “Використання свіжої води на потреби за регіонами”</li>
        </ul>  
      </li>
      <li><a href="https://davr.gov.ua/derzhavnij-oblik-vodokoristuvannya" target="_blank">Звіт</a> держводагентства “Державний облік водокористування”: Загальні показники використання водних ресурсів, 2021-2022 роки, у територіальному розрізі</li>
    </ol>
    `,

      "en": `
    <strong>Crop Yield</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_rosl_zb.htm" target="_blank">Yearbook</a> “Plant Growing in Ukraine”, 2009-2021
        <ul><li>Tables in yearbook “Yield of… <em>(name of agricultural crop)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/operativ2022/sg/pvzu/arch_pvxu_reg.htm" target="_blank">Bulletin</a> “Areas, gross collections, yield of agricultural crops, by their types and regions”, 2022
        <ul><li>Tables in bulletin “Production of… <em>(name of agricultural crop)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/menu/menu_u/cg.htm" target="_blank">Table</a> “Crops – growing”, 1991-2022 years</li>
      <li><a href="http://nbuv.gov.ua/node/3857" target="_blank">Yearbook</a> “The national economy of the Ukrainian SSR”, 1990
        <ul>
          <li>Table in yearbook “Yield of agricultural crops”</li>
          <li>Table in yearbook “Yield of individual grain crops (in weight after cleaning and drying)”</li>
          <li>Table in yearbook “Gross collections and yield of fruits, berries and grapes”</li>
        </ul>  
      </li>
    </ol>
    
    <strong>Sown area</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_rosl_zb.htm" target="_blank">Yearbook</a> “Plant Growing in Ukraine”, 2009-2021
        <ul><li> Tables in yearbook “Yield of… <em>(name of agricultural crop)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/operativ2022/sg/pvzu/arch_pvxu_reg.htm" target="_blank">Bulletin</a> “Areas, gross collections, yield of agricultural crops, by their types and regions”, 2022
        <ul><li>Tables in bulletin “Production of … <em>(name of agricultural crop)</em>”</li></ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/01/Arch_zor_zb.htm" target="_blank">Yearbook</a> “Statistical Yearbook of Ukraine”, 2007-2021
        <ul>
          <li>Table in yearbook “Sown area for agricultural crops”</li>
          <li>Table in yearbook “Sown area for agricultural crops, by regions”</li>
        </ul>
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/menu/menu_u/cg.htm" target="_blank">Table</a> “Crops – growing”, 1991-2022</li>
      <li><a href="http://nbuv.gov.ua/node/3857" target="_blank">Yearbook</a> “The national economy of the Ukrainian SSR”, 1990 year
        <ul>
          <li>Table in yearbook “Sown area”</li>
          <li>Table in yearbook “Area of fruit, berry and grape plantations”</li>
        </ul>
      </li>
    </ol>
    
    <strong>Fertilizers</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_mod_bl.htm" target="_blank">Bulletin</a> “Use of inorganic and organic fertilizers at agricultural enterprises under the harvest of agricultural crops”, 2009-2017
        <ul>
          <li>Table in bulletin “Use of inorganic and organic fertilizers under the harvest by agricultural enterprises”</li>
          <li>Tables in bulletin “Use of inorganic and organic fertilizers under… (name of agricultural crop) of the harvest by agricultural enterprises”</li>
        </ul>  
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/operativ2018/sg/vmod/arch_vmodsg_u.htm" target="_blank">Bulletin</a> “Use of fertilizers and pesticides for the harvest of agricultural crops”, 2018-2022
        <ul>
          <li>Table in bulletin “The area treated with fertilizers and pesticides for the harvest of agricultural crops, by regions”</li>
          <li>Table in bulletin “Use of inorganic fertilizers (in active substance) for the harvest of agricultural crops, by regions”</li>
          <li>Table in bulletin “Use of organic fertilizers for the harvest of agricultural crops, by regions”</li>
        </ul>    
      </li>
      <li><a href="https://ukrstat.gov.ua/operativ/menu/menu_u/cg.htm" target="_blank">Table</a> “Use of inorganic and organic fertilizers, use of pesticides”, 1990-2022</li>
      <li><a href="http://nbuv.gov.ua/node/3857" target="_blank">Yearbook</a> “The national economy of the Ukrainian SSR”, 1990
        <ul><li>Table in yearbook “Supply of mineral fertilizers to the agriculture industry”</li></ul>
      </li>
    </ol>

    <strong>Irrigation</strong>
    <ol>
      <li><a href="https://ukrstat.gov.ua/druk/publicat/Arhiv_u/07/Arch_dov_zb.htm" target="_blank">Yearbook</a> “Environment of Ukraine”, 2009-2020
        <ul>
          <li>Table in yearbook “Main indicators of water supply and discharges”</li>
          <li>Table in yearbook “Raw water use on various needs, by regions”</li>
        </ul>  
      </li>
      <li><a href="https://davr.gov.ua/derzhavnij-oblik-vodokoristuvannya" target="_blank">Report</a> of the State Water Agency "State Accounting of Water Use": General indicators of water resources usage, 2021-2022, by territory</li>
    </ol>
    `
    }
  },
  {
    id: 4,
    heading: {
      "ua": "Партнери та підтримка",
      "en": "Partners and Support"
    },
    content: {
      "ua": `Платформа AgroStats є частиною проєкту “Climate -induced drought or flood and its impact on the environment and agriculture production in Ukraine and Texas”, що реалізовуєтсься у партнерстві з <a href="https://agriliferesearch.tamu.edu/" target="_blank">Texas A&M AgriLife Research</a> за підтримки <a href="https://research.ibm.com/" target="_blank">IBM Research</a>.
    <br/>
    <div class="partner-logo-container">
      <img class="partner-logo" src="images/Agrilife-logo.svg"/>
      <img class="partner-logo" src="images/IBM.svg"/>
    </div>
    `,
      "en": `The AgroStats platform is part of the project "Climate-induced drought or flood and its impact on the environment and agriculture production in Ukraine and Texas", implemented in partnership with <a href="https://agriliferesearch.tamu.edu/" target="_blank">Texas A&M AgriLife Research</a> and supported by <a href="https://research.ibm.com/" target="_blank">IBM Research</a>.
    <br/>
    <div class="partner-logo-container">
      <img class="partner-logo" src="images/Agrilife-logo.svg"/>
      <img class="partner-logo" src="images/IBM.svg"/>
    </div>
    `
    }
  },
  {
    id: 5,
    heading: {
      "ua": "Контакти",
      "en": "Contacts"
    },
    content: {
      "ua": `
    <div id="article-contacts">
      <a href="https://www.facebook.com/uhmi.org.ua" target="_blank">
        <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="100%" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z" style="fill:#1777f2;"/></svg>

        Український гідрометеорологічний інститут
      </a>

      <br/>
      <a href="mailto:valery.osypov@gmail.com">
        <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="_x31_12-gmail_x2C__email_x2C__mail"><g><g><g><rect height="358.87" style="fill:#F1F5F7;" width="357.904" x="77.045" y="76.565"/><path d="M256.002,293.738l178.947,141.697v-279.74L256.002,293.738z M256.002,293.738" style="fill:#DCE6EA;"/><path d="M449.861,76.565h-14.912L256.002,218.26L77.045,76.565H62.134      c-24.693,0-44.737,20.094-44.737,44.858v269.152c0,24.759,20.044,44.859,44.737,44.859h14.911v-279.74l178.957,138.014      l178.947-138.047v279.773h14.912c24.699,0,44.742-20.101,44.742-44.859V121.424C494.604,96.66,474.561,76.565,449.861,76.565      L449.861,76.565z M449.861,76.565" style="fill:#F84437;"/></g></g></g></g><g id="Layer_1"/></svg>
        Лабораторія моделювання річкових систем
        <br/>
        valery.osypov@gmail.com
      </a>
    </div>`,
      "en": `
    <div id="article-contacts">
      <a href="https://www.facebook.com/uhmi.org.ua" target="_blank">
        <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="100%" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z" style="fill:#1777f2;"/></svg>
        Ukrainian Hydrometeorological Institute
      </a>

      <br/>
      <a href="mailto:valery.osypov@gmail.com">
        <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="_x31_12-gmail_x2C__email_x2C__mail"><g><g><g><rect height="358.87" style="fill:#F1F5F7;" width="357.904" x="77.045" y="76.565"/><path d="M256.002,293.738l178.947,141.697v-279.74L256.002,293.738z M256.002,293.738" style="fill:#DCE6EA;"/><path d="M449.861,76.565h-14.912L256.002,218.26L77.045,76.565H62.134      c-24.693,0-44.737,20.094-44.737,44.858v269.152c0,24.759,20.044,44.859,44.737,44.859h14.911v-279.74l178.957,138.014      l178.947-138.047v279.773h14.912c24.699,0,44.742-20.101,44.742-44.859V121.424C494.604,96.66,474.561,76.565,449.861,76.565      L449.861,76.565z M449.861,76.565" style="fill:#F84437;"/></g></g></g></g><g id="Layer_1"/></svg>
        Laboratory of the river systems modeling
        <br/>
        valery.osypov@gmail.com
      </a>
    </div>`
    }
  }
]),
  activeHeadingId = ref(0),
  langStore = useLangStore()

const chosenLang = computed(() => langStore.chosenLangGetter)

onMounted(() => {
  let el = document.querySelector('.popup__content');

  // TODO now it uses top scroll position, so small bottom articles are "unreachable"
  el.addEventListener("scroll", (e) => {
    for (let i = 0; i < content.value.length; i++) {
      if (document.querySelector(`#article-${i}`).offsetTop - 24 > el.scrollTop) {
        activeHeadingId.value = Math.max(i - 1, 0);
        break;
      }
    }
  })
})
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  width: 1084px;
  height: 700px;
  top: 140px;
  margin-left: calc((100vw - 1084px) / 2);
  scale: 0.5;
  transition: .5s;
  background-color: #FFFBDE;
  border-radius: 12px;
  display: flex;
  flex-direction: row;

  &__cross {
    position: absolute;
    right: -18px;
    top: -18px;
    cursor: pointer;
  }

  &__wrapper {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    left: 0;
    top: 0;
    background-color: #00000080;
    z-index: -1;
    transition: .5s;
    opacity: 0;

    &--active {
      opacity: 1;
      z-index: 900;
      cursor: pointer;

      .popup {
        scale: 1;
        cursor: auto;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
    padding: 0 100px 50px;
    gap: 24px;
    scroll-behavior: smooth;
    overflow-y: auto;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 280px;
    border-left: 2px solid #00000060;
    padding: 24px 12px;
    background-color: #FEC44F40;
  }
}

.article {
  font-family: "Roboto";

  &__heading {
    text-align: start;
    font-weight: 500;
    font-size: 32px;
    margin: 24px auto;
    scroll-margin-top: 24px;
  }

  &__content {
    font-weight: 300;
    font-size: 20px;
  }
}

.menu {
  &__heading {
    display: block;
    font-family: "Roboto";
    text-decoration: unset;
    color: #000;
    font-size: 18px;
    font-weight: 300;

    &--active {
      font-weight: 500;

      &::before {
        content: '> ';
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .popup {
    width: 90vw;
    margin-left: 5vw;
  }
}

@media screen and (max-height: 900px) {
  .popup {
    top: calc(60px + 2.5dvh);
    height: calc(100dvh - 60px - 5dvh);

    &__content {
      width: calc(100% - 160px);
      padding: 0 24px 50px;
    }

    &__menu {
      width: 160px;
      overflow-wrap: anywhere;
    }
  }
}

@media screen and (max-width: 800px) {
  .popup {
    flex-direction: column-reverse;

    &__menu {
      display: none;
      flex-direction: column;
      width: 100%;
      align-items: center;
      overflow-wrap: unset;
    }

    .menu {
      &__heading {
        text-wrap: nowrap;
      }
    }

    &__content {
      width: 100%;
    }

    .article {
      &__heading {
        margin: 16px auto;
        scroll-margin-top: 16px;
        font-size: 28px;
      }

      &__content {
        font-size: 18px;
        line-height: 1.1;
      }
    }
  }
}
</style>